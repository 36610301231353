import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BadgeIcon from '@mui/icons-material/Badge';
import ArticleIcon from '@mui/icons-material/Article';
import PaymentIcon from '@mui/icons-material/Payment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { RequestPage, Settings } from '@mui/icons-material';
import { RIGHTS_MAPPING } from './utilConstants';

export const menus = [
    // {
    //     id: 1,g
    //     parentId: null,
    //     name: "Angajati",
    //     to: null,
    //     isCategory: true,
    //     icon: BadgeIcon,  // Add the icon here
    //     locationsType: ["salon", "barber", "salonbarber", "office"],
    //     excludelocationsType: [],
    //     rights: [RIGHTS_MAPPING.ADMIN],
    //     order: 10,
    //     children: [
    //         {
    //             id: 101,
    //             parentId: 1,
    //             name: "Op Card",
    //             to: '/dashboard/opcard',
    //             isCategory: false,
    //             locationsType: ["salon", "barber", "salonbarber", "office"],
    //             excludelocationsType: [],
    //             rights: [RIGHTS_MAPPING.ADMIN],
    //             order: 11,
    //         },
            // {
            //     id: 2,
            //     parentId: 1,
            //     name: "Datorii",
            //     to: '/dashboard/debts',
            //     isCategory: false,
            //     locationsType: ["salon", "barber", "salonbarber", "office"],
            //     excludelocationsType: [],
            //     rights: [2,3],
            //     order: 11,
            // },
    //     ]
    // },
    // {
    //     id: 2,
    //     parentId: null,
    //     name: "Receptie",
    //     to: null,
    //     icon: ArticleIcon,
    //     isCategory: true,
    //     locationsType: ["salon", "barber", "salonbarber", "office"],
    //     excludelocationsType: [],
    //     rights: [RIGHTS_MAPPING.MANAGER, RIGHTS_MAPPING.ADMIN],
    //     order: 20,
    //     children: [
    //         {
    //             id: 201,
    //             parentId: 3,
    //             name: "Cereri de plata",
    //             to: "/dashboard/paymentrequests",
    //             isCategory: false,
    //             locationsType: ["salon", "barber", "salonbarber", "office"],
    //             excludelocationsType: [],
    //             rights: [RIGHTS_MAPPING.MANAGER, RIGHTS_MAPPING.ADMIN],
    //             order: 21,
    //         },
            // {
            //     id: 5,
            //     parentId: 3,
            //     name: "Borderou incasari",
            //     to: "/dashboard/receiptsummary",
            //     isCategory: false,
            //     locationsType: ["salon", "barber", "salonbarber", "office"],
            //     excludelocationsType: [],
            //     rights: [1,2,3],
            //     order: 22,
            // },
    //     ]
    // },
    // {
    //     id: 3,
    //     parentId: null,
    //     name: "Salarii/Ore lucrate",
    //     to: "/dashboard/salarywork",
    //     isCategory: true,
    //     icon: PaymentIcon,
    //     locationsType: ["salon", "barber", "salonbarber", "office"],
    //     excludelocationsType: [],
    //     rights: [RIGHTS_MAPPING.MANAGER, RIGHTS_MAPPING.ADMIN],
    //     order: 30,
    //     children: [
    //         {
    //             id: 301,
    //             parentId: 6,
    //             name: "Pontaj",
    //             to: "/dashboard/pontaj",
    //             isCategory: false,
    //             locationsType: ["salon", "barber", "salonbarber", "office"],
    //             excludelocationsType: [],
    //             rights: [RIGHTS_MAPPING.MANAGER, RIGHTS_MAPPING.ADMIN],
    //             order: 31,
    //         },
    //     ],
    // },
    // {
    //     id: 4,
    //     parentId: null,
    //     name: "Activitate Manageri",
    //     to: "/dashboard/managersactivity",
    //     isCategory: false,
    //     locationsType: ["salon", "barber", "salonbarber"],
    //     excludelocationsType: [],
    //     rights: [RIGHTS_MAPPING.ANGAJAT, RIGHTS_MAPPING.MANAGER, RIGHTS_MAPPING.ADMIN],
    //     order: 40,
    //     children: null,
    // },
    // {
    //     id: 5,
    //     parentId: null,
    //     name: "Activitate Traineri",
    //     to: "/dashboard/trainersactivity",
    //     isCategory: false,
    //     locationsType: ["salon", "barber", "salonbarber"],
    //     excludelocationsType: [],
    //     rights: [RIGHTS_MAPPING.ANGAJAT, RIGHTS_MAPPING.MANAGER, RIGHTS_MAPPING.ADMIN],
    //     order: 50,
    //     children: null,
    // },
    // {
    //     id: 6,
    //     parentId: null,
    //     name: "Statistici",
    //     to: null,
    //     isCategory: true,
    //     locationsType: ["salon", "barber", "salonbarber"],
    //     excludelocationsType: [],
    //     rights: [RIGHTS_MAPPING.MANAGER, RIGHTS_MAPPING.ADMIN],
    //     order: 70,
    //     children: [
    //         {
    //             id: 601,
    //             parentId: 10,
    //             name: "Salarii Luna Curenta",
    //             to: "/dashboard/payroll",
    //             isCategory: false,
    //             locationsType: ["salon", "barber", "salonbarber"],
    //             excludelocationsType: [],
    //             rights: [RIGHTS_MAPPING.MANAGER, RIGHTS_MAPPING.ADMIN],
    //             order: 71,
    //         },
    //         {
    //             id: 602,
    //             parentId: 10,
    //             name: "Istoric Salarii",
    //             to: "/dashboard/payrollhistory",
    //             isCategory: false,
    //             locationsType: ["salon", "barber", "salonbarber"],
    //             excludelocationsType: [],
    //             rights: [RIGHTS_MAPPING.MANAGER, RIGHTS_MAPPING.ADMIN],
    //             order: 72,
    //         },
    //         {
    //             id: 603,
    //             parentId: 10,
    //             name: "Statistica Traineri",
    //             to: "/dashboard/trainersstatistics",
    //             isCategory: false,
    //             locationsType: ["salon", "barber", "salonbarber"],
    //             excludelocationsType: [],
    //             rights: [RIGHTS_MAPPING.MANAGER, RIGHTS_MAPPING.ADMIN],
    //             order: 72,
    //         },
    //         {
    //             id: 604,
    //             parentId: 10,
    //             name: "Statistica Manageri",
    //             to: "/dashboard/managersstatistics",
    //             isCategory: false,
    //             locationsType: ["salon", "barber", "salonbarber"],
    //             excludelocationsType: [],
    //             rights: [RIGHTS_MAPPING.MANAGER, RIGHTS_MAPPING.ADMIN],
    //             order: 72,
    //         },
    //     ]
    // },
    {
        id: 7,
        parentId: null,
        name: "Setari",
        to: null,
        isCategory: true,
        locationsType: ["office"],
        excludelocationsType: [],
        rights: [RIGHTS_MAPPING.ANGAJAT, RIGHTS_MAPPING.MANAGER, RIGHTS_MAPPING.ADMIN],
        order: 80,
        icon: Settings,
        children: [
            // {
            //     id: 701,
            //     parentId: 10,
            //     name: "Manageri Saloane",
            //     to: "/dashboard/salonManager",
            //     isCategory: false,
            //     locationsType: ["office"],
            //     excludelocationsType: [],
            //     rights: [RIGHTS_MAPPING.ADMIN],
            //     order: 81,
            // },
            {
                id: 702,
                parentId: 10,
                name: "Angajati",
                to: "/dashboard/employees",
                isCategory: false,
                locationsType: ["office"],
                excludelocationsType: [],
                rights: [RIGHTS_MAPPING.MANAGER, RIGHTS_MAPPING.ADMIN],
                order: 81,
            },
            {
                id: 703,
                parentId: 10,
                name: "Descrieri Activitati Timp",
                to: "/dashboard/timeActivityDescription",
                isCategory: false,
                locationsType: ["office"],
                excludelocationsType: [],
                rights: [],
                groups: ['DRD'],
                order: 81,
            },
        ],
    },
    {
        id: 8,
        parentId: null,
        name: "Cerere Fonduri",
        to: "/dashboard/requestFunds",
        isCategory: false,
        locationsType: [],
        excludelocationsType: [],
        groups: ['CFS', 'CFA'],
        rights: [],
        order: 90,
        icon: RequestPage,
        children: []
    },
    {
        id: 9,
        parentId: null,
        name: "Tabela Activitati",
        to: "/dashboard/activityTable",
        isCategory: false,
        locationsType: ["office"],
        excludelocationsType: [],
        groups: [],
        rights: [RIGHTS_MAPPING.ANGAJAT, RIGHTS_MAPPING.MANAGER, RIGHTS_MAPPING.ADMIN],
        order: 100,
        icon: AccessTimeIcon,
        children: []
    }
]