import { Box, MenuItem, Select, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { apiGetLocationManagers, apiGetLocationsForUser, apiUpdateManagerId } from "../../api/locations";
import { shouldShowPage, showErrorToast, showSuccessToast } from "../../utils/utilFunctions";
import { apiGetDepartments } from "../../api/departments";
import { apiGetEmployeeBy } from "../../api/employee";
import { useLocation } from "react-router-dom";
import NoRights from "../../components/NoRights";

const SalonManager = ({ selectedLocation, userRightsForLocation }) => {
    const location = useLocation();
    const [locations, setLocations] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [deparments, setDepartments] = useState([]);
    const [locationManagers, setLocationManagers] = useState([]);
    const [managers, setManagers] = useState({});


    useEffect(() => {
        getInitialData()
    }, [])

    const getInitialData = async () => {
        apiGetDepartments(getEmployees, showErrorToast)
        apiGetLocationsForUser(setLocations, showErrorToast)
        await apiGetLocationManagers(setManagersFromApi, showErrorToast)
    }

    const setManagersFromApi = (locationManagersResponse) => {
        setLocationManagers(locationManagersResponse)
        let managersTmp = {}
        locationManagersResponse.forEach((locationManager) => {
            managersTmp = { ...managersTmp, [locationManager.location_id]: locationManager.employee_id }
        })
        setManagers({ ...managers, ...managersTmp })
    }

    const getEmployees = (departments) => {
        const departmentIds = departments.filter(department => { return department.name === "Academie" || department.name === "ManagementSalon" }).flatMap(item => { return item.id })
        console.log(departmentIds)
        apiGetEmployeeBy(setEmployees, showErrorToast, `department_id=${departmentIds}`)
    }

    const onChange = (event) => {
        const { name, value } = event.target;
        setManagers({ ...managers, [name]: value })
        console.log(name, value)
        const data = { location_id: name, employee_id: value }
        apiUpdateManagerId(showSuccessToast, showErrorToast, data)
    }

    return (
        <Box className="flex gap-6 flex-col">
            {shouldShowPage(userRightsForLocation, location.pathname) ? (
                <Box>
                    <Typography variant="h4">
                        <span className="font-bold text-black">Manageri Saloane</span>
                    </Typography>
                    <Box>
                        {locations.map(location => {
                            return <Box key={`location-${location.id}`} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 1 }}>
                                <Typography>
                                    {location.name}
                                </Typography>
                                <Select
                                    className="p-0"
                                    name={String(location.id)}
                                    value={managers[location.id] || ''}
                                    onChange={onChange}
                                    displayEmpty
                                    sx={{
                                        minWidth: 220,
                                        fontSize: '0.875rem',
                                        padding: '0px', // Smaller padding for Select
                                        minHeight: '20px', // Adjust the height of the Select component
                                        '.MuiSelect-select': {
                                            padding: '6px 8px' // Customize the internal input padding
                                        },
                                    }}
                                >

                                    {employees.map((item) =>
                                        <MenuItem sx={{ fontSize: '0.875rem' }} key={`location-menu-${item.id}`} value={item.id}>{item.name}</MenuItem>)}
                                </Select>
                            </Box>
                        })}
                    </Box>
                </Box>
            ) : <NoRights />}

        </Box>
    )
}
export default SalonManager;