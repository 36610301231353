import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Box, useTheme, useMediaQuery } from '@mui/material';
import { AccountCircle, Menu as MenuIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { removeToken } from '../utils/utilFunctions';
import { useEffect } from 'react';

const Navbar = ({ onMenuClick, user, dropdownItems, selectedDropdownItem, setSelectedDropdownItem }) => {
    const navigate = useNavigate(); // Initialize navigate function
    const theme = useTheme();
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [dropdownAnchorEl, setDropdownAnchorEl] = useState(null);
    const isDropdownOpen = Boolean(dropdownAnchorEl);

    const isMenuOpen = Boolean(anchorEl);

    const handleDropdownClick = (event) => {
        setDropdownAnchorEl(event.currentTarget);
    };

    const handleDropdownItemClick = (item) => {
        // Handle item selection here
        setSelectedDropdownItem(item)
        setDropdownAnchorEl(null);
    };

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };


    const handleProfileClick = () => {
        navigate('/dashboard/profile');
        handleMenuClose();
    }

    const handleLogoutClick = () => {
        handleMenuClose();
        removeToken();
        navigate('/auth');
    }

    const handleMenuClose = () => {
        setAnchorEl(null);
        setDropdownAnchorEl(null);
    };

    return (
        <AppBar position="fixed" sx={{ backgroundColor: theme.palette.primary.main, zIndex: theme.zIndex.drawer + 1 }}>
            <Toolbar className="flex items-center justify-between">
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={onMenuClick} // Handle menu click here
                >
                    <MenuIcon />
                </IconButton>

                <Box component="div" className="flex items-center">
                    <IconButton
                        color="inherit"
                        onClick={handleDropdownClick}
                        aria-controls="dropdown-menu"
                        aria-haspopup="true"
                        sx={{ padding: '8px', borderRadius: '4px' }}
                    >
                        <Typography variant="body1" className="text-white">
                            {selectedDropdownItem ? selectedDropdownItem.name : t('select-location')}
                        </Typography>
                    </IconButton>
                    <Menu
                        id="dropdown-menu"
                        anchorEl={dropdownAnchorEl}
                        keepMounted
                        open={isDropdownOpen}
                        onClose={handleMenuClose} // Close the menu when clicking outside
                    >
                        {dropdownItems.map((item) => (
                            <MenuItem
                                key={item.name}
                                onClick={() => handleDropdownItemClick(item)}
                            >
                                {item.name}
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
                <Box className="flex flex-grow flex-row justify-center">
                    {/* <Typography variant="h4" className="text-white">{(user && user.company) ? user.company : t('company-settings-notification')}</Typography> */}
                </Box>
                <Box className="flex flex-row">
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleProfileMenuOpen}
                        aria-controls="profile-menu"
                        aria-haspopup="true"
                    >
                        <AccountCircle />
                    </IconButton>
                </Box>
                <Menu
                    id="profile-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={isMenuOpen}
                    onClose={handleMenuClose} // Close the menu when clicking outside
                >
                    <MenuItem onClick={handleProfileClick}>{t('Profile')}</MenuItem>
                    <MenuItem onClick={handleLogoutClick}>{t('Logout')}</MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
