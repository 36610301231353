import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import './../i18n';
import { Navigate, Route, Routes } from "react-router-dom";
import routes from "./../routes.js";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

const Auth = (props) => {
  const navigate = useNavigate(); // Initialize navigate function
  const { t, i18n } = useTranslation();

  useEffect(() => {
    checkLogin()
  }, [])

  const checkLogin = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    try {
      const response = await fetch(`${apiUrl}/api/checkLogin`, {
        method: 'GET', // or 'GET' depending on your APIS
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      if (data.success) {
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route path={prop.path} element={prop.component} key={key} exact />
        );
      } else {
        return null;
      }
    });
  };

  React.useEffect(() => {
    document.body.classList.add("bg-gray-300");
    return () => {
      document.body.classList.remove("bg-gray-300");
    };
  }, []);

  return (
    <>
      <div className="font-sans w-full h-[100dvh] flex items-center justify-center">
        <Card className="w-[500px] max-w-[350px] sm:max-w-[500px] h-fit">
          <CardContent>
            <Routes>
              {getRoutes(routes)}
              <Route path="*" element={<Navigate to="/auth/login" replace />} />
            </Routes>
          </CardContent>
        </Card>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000} // Adjust the duration for which the toast should be visible
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default Auth;