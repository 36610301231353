import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
} from '@mui/material';
import { createEmployeeActivities, deleteEmployeeActivity, getDepartmentActivities, getEmployeeActivities } from '../../api/activityTable';
import { formatDateTime, showErrorToast, showSuccessToast } from '../../utils/utilFunctions';

const localizer = momentLocalizer(moment);

const ActivityTable = ({ employee }) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [activityId, setActivityId] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [departmentActivities, setDepartmentActivities] = useState([]);
    const [employeeActivities, setEmployeeActivities] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);

    const handleSelectSlot = ({ start, end }) => {
        setSelectedSlot({ start, end });
        setStartTime(moment(start).format('YYYY-MM-DDTHH:mm')); // Pre-fill start time
        setEndTime(moment(end).format('YYYY-MM-DDTHH:mm')); // Pre-fill end time
        setDialogOpen(true); // Open dialog
    };

    useEffect(() => {
        if (employee) {
            getDepartmentActivities(setDepartmentActivities, showErrorToast, employee.department_id)
            getEmployeeActivities(parseActivities, showErrorToast, employee.id)
        }
    }, [employee])

    const parseActivities = (data) => {
        const parsedActivities = data.map(activity => ({
            ...activity,
            start: new Date(activity.start_date),  // Ensure that start and end are Date objects
            end: new Date(activity.end_date),
            title: activity.activity_name, // Set the title for display in the calendar
        }));

        setEmployeeActivities(parsedActivities); // Update the state with parsed activities
    };

    const handleSaveEvent = () => {
        if (activityId && startTime && endTime) {
            setDialogOpen(false); // Close dialog after saving
            setActivityId('');

            const reqData = {
                employee_id: employee.id,
                activity_id: activityId,
                start_date: new Date(startTime),
                end_date: new Date(endTime)
            }

            createEmployeeActivities(handleSuccessCallback, showErrorToast, reqData)
        }
    };
    const handleSuccessCallback = (message) => {
        showSuccessToast(message);
        getEmployeeActivities(parseActivities, showErrorToast, employee.id)
    }

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleSelectEvent = async (event) => {
        setSelectedEvent(event); // Store the selected event
        setDeleteDialogOpen(true)
    }

    const handleDeleteEvent = () => {
        setDeleteDialogOpen(false);
        deleteEmployeeActivity(handleSuccessCallback, showErrorToast, selectedEvent.activity_entry_id)
    };

    return (
        <div style={{ height: 500 }}>
            <Calendar
                localizer={localizer}
                events={employeeActivities}
                selectable
                defaultView="week"
                onSelectSlot={handleSelectSlot} // Trigger the dialog on slot selection
                onSelectEvent={handleSelectEvent}
                startAccessor="start"
                endAccessor="end"
                min={new Date(1970, 1, 1, 6, 0)} // 6:00 AM
                max={new Date(1970, 1, 1, 22, 0)} // 10:00 PM
                style={{ height: 500 }}
            />

            {/* Event creation dialog */}
            <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>Adauga activitate noua</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth style={{ marginTop: '1rem' }}>
                        <InputLabel id="dialog-title">Activity</InputLabel>
                        <Select
                            label="Activity"
                            labelId="department-select-label"
                            value={activityId}
                            name="name"
                            onChange={(e) => setActivityId(e.target.value)}
                        >
                            {departmentActivities.map((departmentActivity) => (
                                <MenuItem key={departmentActivity.id} name={departmentActivity.activity_name} value={departmentActivity.activity_id}>
                                    {departmentActivity.activity_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <TextField
                        label="Start Time"
                        type="datetime-local"
                        value={startTime}
                        onChange={(e) => setStartTime(e.target.value)}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        style={{ marginTop: '1rem' }}
                    />

                    <TextField
                        label="End Time"
                        type="datetime-local"
                        value={endTime}
                        onChange={(e) => setEndTime(e.target.value)}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        style={{ marginTop: '1rem' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveEvent} color="primary">
                        Adauga Activitate
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Event edit/delete dialog */}
            {deleteDialogOpen &&
                <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
                    <DialogTitle>Activitate</DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth style={{ marginTop: '1rem' }}>
                            <InputLabel id="edit-dialog-title">Activity</InputLabel>
                            <Select
                                label="Activity"
                                labelId="edit-dialog-select-label"
                                value={selectedEvent.activity_id}
                                name="name"
                                onChange={(e) => setActivityId(e.target.value)}
                                disabled
                            >
                                {departmentActivities.map((departmentActivity) => (
                                    <MenuItem key={departmentActivity.id} name={departmentActivity.activity_name} value={departmentActivity.activity_id}>
                                        {departmentActivity.activity_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField
                            label="Start Time"
                            value={formatDateTime(selectedEvent.start)}
                            onChange={(e) => setStartTime(e.target.value)}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            style={{ marginTop: '1rem' }}
                            disabled
                        />

                        <TextField
                            label="End Time"
                            value={formatDateTime(selectedEvent.end)}
                            onChange={(e) => setEndTime(e.target.value)}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            style={{ marginTop: '1rem' }}
                            disabled
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDeleteDialogOpen(false)} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleDeleteEvent} color="error">
                            Ștergeți Activitatea
                        </Button>
                    </DialogActions>
                </Dialog>
            }
        </div>
    );
};

export default ActivityTable;
