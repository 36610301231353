import { toast } from "react-toastify";
import { menus } from "./menus";

export const NEEDS_UPDATE_STRING = { user: "USER" }

export const storeToken = (token) => {
    localStorage.setItem('authToken', token);
}

export const removeToken = () => {
    localStorage.removeItem('authToken');
}

export const getToken = () => {
    return localStorage.getItem('authToken');
}

export const setLocation = (location) => {
    localStorage.setItem('selected-location', location);
}

export const getLocation = () => {
    return localStorage.getItem('selected-location');
}

export const showErrorToast = (message) => {
    toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
}

export const showSuccessToast = (message) => {
    toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
}

export const shouldShowMenu = (selectedLocation, userRights, menu, employeeGroups) => {
    let shouldShow = true;

    if (selectedLocation) {
        const rightsForThisLocation = userRights.filter((right) => { return right.location_id === selectedLocation.id })
        if (rightsForThisLocation.length < 1) {
            shouldShow = false;
        }
        const right_code = rightsForThisLocation[0].right_code

        if (menu.rights && menu.rights.length !== 0 && !menu.rights.includes(right_code)) {
            shouldShow = false;
        }

        if (menu.locationsType && menu.locationsType.length !== 0 && !menu.locationsType.includes(selectedLocation.type)) {
            shouldShow = false;
        }
    } else {
        if (menu.locationsType && menu.locationsType.length !== 0) {
            shouldShow = false;
        }
    }

    if (menu.groups && menu.groups.length > 0) {
        if (employeeGroups.length === 0) {
            shouldShow = false;
        }

        if (!employeeGroups.some(userGroup => menu.groups.includes(userGroup.group_code))) {
            shouldShow = false;
        }
    }

    return shouldShow;
}

export const shouldSeePage = (selectedLocation, userRights, menu, employeeGroups) => {
    let shouldShow = true;

    if (selectedLocation) {
        const rightsForThisLocation = userRights.filter((right) => { return right.location_id === selectedLocation.id })
        if (rightsForThisLocation.length < 1) {
            shouldShow = false;
        }
        const right_code = rightsForThisLocation[0].right_code

        if (menu.rights && menu.rights.length !== 0 && !menu.rights.includes(right_code)) {
            shouldShow = false;
        }

        if (menu.locationsType && menu.locationsType.length !== 0 && !menu.locationsType.includes(selectedLocation.type)) {
            shouldShow = false;
        }
    } else {
        if (menu.locationsType && menu.locationsType.length !== 0) {
            shouldShow = false;
        }
    }

    if (menu.groups && menu.groups.length >= 0) {
        if (!employeeGroups.some(userGroup => menu.groups.includes(userGroup.group_code))) {
            shouldShow = false;
        }
    }

    return shouldShow;
}

export const formatDateTime = (date) => {
    const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with leading zero if necessary
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month, +1 because getMonth() returns 0-based month
    const year = date.getFullYear(); // Get full year
    const hours = String(date.getHours()).padStart(2, '0'); // Get hours and pad with leading zero if necessary
    const minutes = String(date.getMinutes()).padStart(2, '0'); // Get minutes and pad with leading zero if necessary

    return `${day}.${month}.${year} ${hours}:${minutes}`;
}

export const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with leading zero if necessary
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month, +1 because getMonth() returns 0-based month
    const year = date.getFullYear(); // Get full year

    return `${day}.${month}.${year}`;
}

export const formatMinutesToString = (minutes) => {
    return `${parseInt(minutes / 60) !== 0 ? `${parseInt(minutes / 60)} ${parseInt(minutes / 60) === 1 ? `Hour` : `Hours`}` : ""} ${parseInt(minutes % 60) >= 1 && parseInt(minutes / 60) >= 1 ? `and` : ""} ${minutes % 60 !== 0 ? `${minutes % 60} ${minutes % 60 === 1 ? `Minute` : `Minutes`}` : ""}`
}

export const getHoursWithHalfHourInterval = () => {
    const array = []
    array.push({ id: 0, minutes: 0, name: "0" })
    for (let i = 30; i <= 12 * 60; i += 30) {
        array.push({ id: array[array.length - 1].id + 1, minutes: i, name: formatMinutesToString(i) })
    }
    return array;
}

export const formatDateToYYYYMMDD = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based, so we add 1
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export const shouldShowPage = (userRightsForLocation, page) => {
    const menuTmp = menus.flatMap(item => {
        // If children exist and are non-empty, include the children in the same list
        if (item.children && item.children.length > 0) {
            return [item, ...item.children];
        }
        // If there are no children, return the item itself
        return item;
    })

    if (userRightsForLocation.length === 0) {
        return false
    }

    const results = menuTmp.filter((item) => { return (item.to === page && item.locationsType.includes(userRightsForLocation[0]?.location_type)) });
    const resultsFinal = results.filter((result) => result.rights.includes(userRightsForLocation[0].right_code))
    return resultsFinal.length !== 0
}