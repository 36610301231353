import React, { useState } from 'react';
import { TextField, Button, Typography, Box, MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { apiEditEmployee } from '../../api/employee';
import { NEEDS_UPDATE_STRING, showErrorToast, showSuccessToast } from '../../utils/utilFunctions';
import { apiEditEmployeeDetails, apiGetEmployeeDetails } from '../../api/employeeDetails';
import { apiUpdateUserRights } from '../../api/rights';

const Profile = ({
    locations = [],
    departments = [],
    user = {},
    positions = [],
    updateData = () => { console.log("NO UPDATE FUNCTION SET") },
    rights = [],
    userRights = [],
    employeeGroups = [],
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        setEmployeeFormData({
            name: user?.name || '',
            fname: user?.fname || '',
            lname: user?.lname || '',
            email: user?.email || '',
            password: '',
            department_id: user?.department_id || '',
            location_id: user?.location_id || '',
            level: user?.level || '',
            top_month: user?.top_month || '',
            fee_per_day: user?.fee_per_day || '',
            concediu: user?.concediu || '',
            cnp: user?.cnp || '',
            active: user?.active || '0',
            fee_discount: user?.fee_discount || '',
            fee_standard: user?.fee_standard || '',
            fee_busy: user?.fee_busy || '',
            fee_fire: user?.fee_fire || '',
            fee_intens: user?.fee_intens || '',
            position_id: user?.position_id || ''
        })
    }, [user])

    // Example form state, you would replace this with actual data fetching and state management logic
    const [employeeFormData, setEmployeeFormData] = useState({
        name: user?.name || '',
        fname: user?.fname || '',
        lname: user?.lname || '',
        email: user?.email || '',
        password: '',
        department_id: user?.department_id || '',
        location_id: user?.location_id || '',
        level: user?.level || '',
        top_month: user?.top_month || '',
        fee_per_day: user?.fee_per_day || '',
        concediu: user?.concediu || '',
        cnp: user?.cnp || '',
        active: user?.active || '0',
        fee_discount: user?.fee_discount || '',
        fee_standard: user?.fee_standard || '',
        fee_busy: user?.fee_busy || '',
        fee_fire: user?.fee_fire || '',
        fee_intens: user?.fee_intens || '',
        position_id: user?.position_id || ''
    });

    const [employeeDetailsFormData, setEmployeeDetailsFormData] = useState({
        studies: '',
        marital_status: '',
        drivers_licence: '',
        address_county: '',
        address_city: '',
        address_street: '',
        has_children: '',
        emergency_fname: '',
        emergency_lname: '',
        emergency_phone: '',
        social_facebook: '',
        social_instagram: ''
    });
    const [userRightsFormData, setUserRightsFormData] = useState({})
    const [edittedUserRightsFormData, setEdittedUserRightsFormData] = useState({})
    const [canEdit, setCanEdit] = useState(false)

    useEffect(() => {
        // if (employeeGroups.filter(employeeGroup => employeeGroup.group_code === "USE").length > 0) {
        //     setCanEdit(true);
        // }
        setCanEdit(false);
    }, [employeeGroups])
    useEffect(() => {
        apiGetEmployeeDetails(setEmployeeDetailsFormData)
    }, [])

    useEffect(() => {
        parseUserRights()
    }, [userRights])

    const parseUserRights = () => {
        let userRightsFormDataTmp = {}
        userRights.map((userRight) => {
            userRightsFormDataTmp[userRight.location_id] = userRight.right_id
        })
        setUserRightsFormData({ ...userRightsFormDataTmp })
    }

    const [edittedFields, setEdittedFields] = useState({})
    const [edittedEmployeeDetailsFields, setEdittedEmployeeDetailsFields] = useState({})

    const handleChange = (event) => {
        const { name, value } = event.target;
        setEmployeeFormData({ ...employeeFormData, [name]: value });
        setEdittedFields({ ...edittedFields, [name]: value })
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Implement form submission logic here
        apiEditEmployee(editResponse, edittedFields)
    };

    const editResponse = (response) => {
        if (response.success) {
            showSuccessToast(response.message)
            updateData(NEEDS_UPDATE_STRING.user)
        } else {
            showErrorToast(response.message)

        }
    }

    const handleChangeEmployeeDetails = (event) => {
        const { name, value } = event.target;
        setEmployeeDetailsFormData({ ...employeeDetailsFormData, [name]: value })
        setEdittedEmployeeDetailsFields({ ...edittedEmployeeDetailsFields, [name]: value })
    };

    const handleSubmitEmployeeDetails = (event) => {
        event.preventDefault();
        // Implement form submission logic here
        apiEditEmployeeDetails(editResponse, edittedEmployeeDetailsFields)
    };

    const handleChangeUserRights = (event) => {
        const { name, value } = event.target;
        setUserRightsFormData({ ...userRightsFormData, [name]: value })
        setEdittedUserRightsFormData({ ...edittedUserRightsFormData, [name]: value })
    }

    const handleRightsSubmit = () => {
        apiUpdateUserRights(editResponse, edittedUserRightsFormData)
    }



    return (
        <Box className="gap-10">
            <Box className="container mx-auto p-4 gap-10 flex flex-col">
                <Typography variant="h4" className="font-bold text-black mb-4">
                    {employeeFormData.name}
                </Typography>
                <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <TextField
                        label={t('Nume Utilizator')}
                        name="name"
                        value={employeeFormData.name}
                        onChange={handleChange}
                        fullWidth
                        disabled={!canEdit}
                    />
                    <TextField
                        label={t('Nume')}
                        name="fname"
                        value={employeeFormData.fname}
                        onChange={handleChange}
                        fullWidth
                        disabled={!canEdit}
                    />
                    <TextField
                        label={t('Prenume')}
                        name="lname"
                        value={employeeFormData.lname}
                        onChange={handleChange}
                        fullWidth
                        disabled={!canEdit}
                    />
                    <TextField
                        label={t('CNP')}
                        name="cnp"
                        type="number"
                        value={employeeFormData.cnp}
                        onChange={handleChange}
                        fullWidth
                        disabled={!canEdit}
                    />
                    <TextField
                        label={t('Email')}
                        name="email"
                        value={employeeFormData.email}
                        onChange={handleChange}
                        fullWidth
                        disabled={!canEdit}
                    />
                    <TextField
                        label={t('Password')}
                        name="password"
                        type="password"
                        value={employeeFormData.password}
                        onChange={handleChange}
                        fullWidth
                        disabled={!canEdit}
                    />
                    <FormControl fullWidth>
                        <InputLabel id="department-label">{t('Departament')}</InputLabel>
                        <Select
                            label={t('Department')}
                            labelId="department-label"
                            name="department_id"
                            value={employeeFormData.department_id}
                            onChange={handleChange}
                            disabled={!canEdit}
                        >
                            {/* Add your department options here */}
                            {departments.map((item) =>
                                <MenuItem key={`department-${item.id}`} value={item.id}>{item.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="salon-label">{t('Locatie')}</InputLabel>
                        <Select
                            label={t('Salon')}
                            labelId="location_id"
                            name="location_id"
                            value={employeeFormData.location_id}
                            onChange={handleChange}
                            disabled={!canEdit}
                        >
                            {/* Add your salon options here */}
                            {locations.map((item) =>
                                <MenuItem key={`location-${item.id}`} value={item.id}>{item.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <TextField
                        label={t('Suma pe zi')}
                        name="fee_per_day"
                        type="number"
                        value={employeeFormData.fee_per_day}
                        onChange={handleChange}
                        fullWidth
                        className="input"
                        disabled={!canEdit}
                    />
                    <TextField
                        label={t('Suma pe zi-discount')}
                        name="fee_discount"
                        type="number"
                        value={employeeFormData.fee_discount}
                        onChange={handleChange}
                        fullWidth
                        className="input"
                        disabled={!canEdit}
                    />
                    <TextField
                        label={t('Suma pe zi-standard')}
                        name="fee_standard"
                        type="number"
                        value={employeeFormData.fee_standard}
                        onChange={handleChange}
                        fullWidth
                        className="input"
                        disabled={!canEdit}
                    />
                    <TextField
                        label={t('Suma pe zi-intens')}
                        name="fee_intens"
                        type="number"
                        value={employeeFormData.fee_intens}
                        onChange={handleChange}
                        fullWidth
                        className="input"
                        disabled={!canEdit}
                    />
                    <TextField
                        label={t('Suma pe zi-busy')}
                        name="fee_busy"
                        type="number"
                        value={employeeFormData.fee_busy}
                        onChange={handleChange}
                        fullWidth
                        className="input"
                        disabled={!canEdit}
                    />
                    <TextField
                        label={t('Suma Concediu')}
                        name="concediu"
                        type="number"
                        value={employeeFormData.concediu}
                        onChange={handleChange}
                        fullWidth
                        className="input"
                        disabled={!canEdit}
                    />
                    <FormControl fullWidth>
                        <InputLabel id="active-label">{t('Active')}</InputLabel>
                        <Select
                            label={t('Active')}
                            labelId="active-label"
                            name="active"
                            value={employeeFormData.active}
                            onChange={handleChange}
                            disabled={!canEdit}
                        >
                            <MenuItem value="1">Da</MenuItem>
                            <MenuItem value="0">Nu</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="position-label">{t('Position')}</InputLabel>
                        <Select
                            label={t('Position')}
                            labelId="position-label"
                            name="position_id"
                            value={employeeFormData.position_id}
                            onChange={handleChange}
                            disabled={!canEdit}
                        >
                            {/* Add your department options here */}
                            {positions.map((item) =>
                                <MenuItem key={`position-${item.id}`} value={item.id}>{item.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <Box className="md:col-span-2 flex justify-end">
                        <Button type="submit" variant="contained" color="primary" disabled={!canEdit}>
                            {t('Save')}
                        </Button>
                    </Box>
                </form>
            </Box>
            <Box className="container mx-auto p-4 gap-10 flex flex-col">
                <Typography variant="h6" className="font-bold text-black mb-4">
                    Date despre angajat
                </Typography>
                <form onSubmit={handleSubmitEmployeeDetails} className="grid grid-cols-1 md:grid-cols-2 gap-4">

                    <FormControl fullWidth>
                        <InputLabel id="studies-label">Studies</InputLabel>
                        <Select
                            label="Studies"
                            labelId="studies-label"
                            name="studies"
                            value={employeeDetailsFormData.studies || ""}
                            onChange={handleChangeEmployeeDetails}
                            fullWidth
                        >
                            <MenuItem value="Scoala primara">Scoala primara</MenuItem>
                            <MenuItem value="Liceu">Liceu</MenuItem>
                            <MenuItem value="Facultate">Facultate</MenuItem>
                            <MenuItem value="Facultate cu master">Facultate cu master</MenuItem>
                            <MenuItem value="Fara studii">Fara studii</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl fullWidth>
                        <InputLabel id="marital-status-label">Marital Status</InputLabel>
                        <Select
                            label="Marital Status"
                            labelId="marital-status-label"
                            name="marital_status"
                            value={employeeDetailsFormData.marital_status || ""}
                            onChange={handleChangeEmployeeDetails}
                            fullWidth
                        >
                            <MenuItem value="Casatorit/a">Casatorit/a</MenuItem>
                            <MenuItem value="Necasatorit/a">Necasatorit/a</MenuItem>
                            <MenuItem value="Divortat/a">Divortat/a</MenuItem>
                            <MenuItem value="Vaduv/a">Vaduv/a</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl fullWidth>
                        <InputLabel id="drivers-licence-label">Driver's Licence</InputLabel>
                        <Select
                            label="Driver's Licence"
                            labelId="drivers-licence-label"
                            name="drivers_licence"
                            value={employeeDetailsFormData.drivers_licence || ""}
                            onChange={handleChangeEmployeeDetails}
                            fullWidth
                        >
                            <MenuItem value="AM">AM</MenuItem>
                            <MenuItem value="A2">A2</MenuItem>
                            <MenuItem value="A1">A1</MenuItem>
                            <MenuItem value="A">A</MenuItem>
                            <MenuItem value="B1">B1</MenuItem>
                            <MenuItem value="B">B</MenuItem>
                            <MenuItem value="C1">C1</MenuItem>
                            <MenuItem value="C">C</MenuItem>
                            <MenuItem value="Fara permis">Fara permis</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        label="County"
                        name="address_county"
                        value={employeeDetailsFormData.address_county || ""}
                        onChange={handleChangeEmployeeDetails}
                        fullWidth
                    />

                    <TextField
                        label="City"
                        name="address_city"
                        value={employeeDetailsFormData.address_city || ""}
                        onChange={handleChangeEmployeeDetails}
                        fullWidth
                    />

                    <TextField
                        label="Street"
                        name="address_street"
                        value={employeeDetailsFormData.address_street || ""}
                        onChange={handleChangeEmployeeDetails}
                        fullWidth
                    />

                    <FormControl fullWidth>
                        <InputLabel id="has-children-label">Has Children</InputLabel>
                        <Select
                            label="Has Children"
                            labelId="has-children-label"
                            name="has_children"
                            value={employeeDetailsFormData.has_children ? 'Yes' : 'No'}
                            onChange={handleChangeEmployeeDetails}
                            fullWidth
                        >
                            <MenuItem value="true">Yes</MenuItem>
                            <MenuItem value="false">No</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        label="Emergency First Name"
                        name="emergency_fname"
                        value={employeeDetailsFormData.emergency_fname || ""}
                        onChange={handleChangeEmployeeDetails}
                        fullWidth
                    />

                    <TextField
                        label="Emergency Last Name"
                        name="emergency_lname"
                        value={employeeDetailsFormData.emergency_lname || ""}
                        onChange={handleChangeEmployeeDetails}
                        fullWidth
                    />

                    <TextField
                        label="Emergency Phone"
                        name="emergency_phone"
                        value={employeeDetailsFormData.emergency_phone || ""}
                        onChange={handleChangeEmployeeDetails}
                        fullWidth
                    />

                    <TextField
                        label="Facebook"
                        name="social_facebook"
                        value={employeeDetailsFormData.social_facebook || ""}
                        onChange={handleChangeEmployeeDetails}
                        fullWidth
                    />

                    <TextField
                        label="Instagram"
                        name="social_instagram"
                        value={employeeDetailsFormData.social_instagram || ""}
                        onChange={handleChangeEmployeeDetails}
                        fullWidth
                    />
                    <Box className="md:col-span-2 flex justify-end">
                        <Button type="submit" variant="contained" color="primary">
                            {t('Save')}
                        </Button>
                    </Box>
                </form>
            </Box>

            <Box className="container mx-auto p-4 gap-10 flex flex-col">
                <Typography variant="h6" className="font-bold text-black mb-4">
                    Drepturi Angajat
                </Typography>

                <Box className="flex flex-col gap-5">
                    {locations.map((location) =>
                        <Box key={`right_location_${location.id}`}>
                            <Typography variant="h6" className="font-bold text-black mb-4">
                                {location.name}
                            </Typography>
                            <FormControl fullWidth >
                                <Select
                                    className='h-[40px]'
                                    name={location.id}
                                    value={userRightsFormData[location.id] || ""}
                                    fullWidth
                                    onChange={handleChangeUserRights}
                                    disabled={!canEdit}
                                >

                                    <MenuItem key={`right_${0}_${location.id}`} value={0}>No Rights</MenuItem>
                                    {rights.map((right) =>
                                        <MenuItem key={`right_${right.id}_${location.id}`} value={right.id}>{right.name}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Box>
                    )}
                </Box>
                <Box className="md:col-span-2 flex justify-end">
                    <Button onClick={handleRightsSubmit} variant="contained" color="primary">
                        {t('Save')}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default Profile;