import React, { useState } from 'react';
import { Box, Typography, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import CSVReader from 'react-csv-reader';
import { formatDate, formatDateTime } from '../utils/utilFunctions';
import { InsertDriveFile } from '@mui/icons-material';

const GenericTable = ({ statuses = [], title, buttonText, buttonAction, importCSV, onImportCSV, columns, data, edit, onEdit, actions = [] }) => {

    // Pagination state
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5); // Default to 5 rows per page

    // Handle page change
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Handle rows per page change
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to first page
    };

    const getStatusIcon = (statusName) => {
        return statuses.filter(status => status.name === statusName)[0].icon
    }

    // Helper to determine if a file is an image
    const isImageFile = (filePath) => {
        const extension = filePath.split('.').pop().toLowerCase();
        return ['jpg', 'jpeg', 'png', 'gif'].includes(extension);
    };

    // Function to download the file
    const handleDownload = (fileUrl) => {
        window.open(fileUrl, '_blank');
    };

    // Calculate the rows to display on the current page
    const paginatedData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <>
            <Box className="flex flex-row justify-between">
                <Typography variant="h4">
                    <span className="font-bold text-black">{title}</span>
                </Typography>
                <Box>
                    {importCSV && (
                        <CSVReader
                            onFileLoaded={onImportCSV}
                            inputStyle={{ display: 'none' }}
                            inputId="csv-input"
                            cssClass="csv-reader-input"
                        />
                    )}
                    {importCSV && (
                        <label htmlFor="csv-input">
                            <Button variant="contained" color="primary" component="span" style={{ marginRight: '10px' }}>
                                Import CSV
                            </Button>
                        </label>
                    )}
                    {buttonText && buttonAction && (
                        <Button variant="contained" color="success" onClick={buttonAction}>
                            {buttonText}
                        </Button>
                    )}
                </Box>
            </Box>

            <TableContainer component={Paper} style={{ marginTop: 20 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column.field}>{column.headerName}</TableCell>
                            ))}
                            {edit && <TableCell>Actions</TableCell>} {/* Add Actions column if edit is true */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedData.map((row, index) => (
                            <TableRow key={index}>
                                {columns.map((column) => {
                                    if (row[column.field] === null) {
                                        return <TableCell key={column.field}></TableCell>
                                    }
                                    if (column.type === 'datetime') {
                                        return <TableCell key={column.field}>{formatDateTime(new Date(row[column.field]))}</TableCell>
                                    } else if (column.type === 'date') {
                                        return <TableCell key={column.field}>{formatDate(new Date(row[column.field]))}</TableCell>
                                    } else if (column.type === 'boolean') {
                                        return <TableCell key={column.field}>{row[column.field] === 0 ? 'false' : 'true'}</TableCell>
                                    } else if (column.type === 'status') {
                                        return <TableCell key={column.field}><Box className="flex gap-1">{row[column.field]}{getStatusIcon(row[column.field])}</Box></TableCell>
                                    } else if (column.type === 'file') {
                                        return (
                                            <TableCell key={column.field}>
                                                {isImageFile(row[column.field]) ? (
                                                    <img
                                                        src={`${process.env.REACT_APP_API_URL}/${row[column.field]}`}
                                                        alt="file"
                                                        style={{ maxHeight: '100px', maxWidth: '100px', cursor: 'pointer' }}
                                                        onClick={() => handleDownload(`${process.env.REACT_APP_API_URL}/${row[column.field]}`)} // Click to download
                                                    />
                                                ) : (
                                                    <IconButton
                                                        onClick={() => handleDownload(`${process.env.REACT_APP_API_URL}/${row[column.field]}`)}
                                                    >
                                                        <InsertDriveFile /> {/* File icon */}
                                                    </IconButton>
                                                )}
                                            </TableCell>
                                        );
                                    } else {
                                        return <TableCell key={column.field}>{row[column.field]}</TableCell>
                                    }
                                })}
                                {(edit || (actions && actions.length > 0)) && (
                                    <TableCell>
                                        <Box display="flex" alignItems="center">
                                            {edit && (
                                                // <TableCell>
                                                <IconButton
                                                    onClick={() => onEdit(row.id)}
                                                    style={{ color: 'black' }} // Set the icon color to black
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                // </TableCell>
                                            )}
                                            {/* Render dynamic action buttons */}
                                            {actions && actions.map((action, i) => (
                                                <IconButton
                                                    key={i}
                                                    onClick={() => action.onClick(row.id)}
                                                    style={{ color: action.color || 'black' }} // Set the icon color dynamically
                                                >
                                                    {action.icon}
                                                </IconButton>
                                            ))}
                                        </Box>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </>
    );
};

GenericTable.propTypes = {
    title: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    buttonAction: PropTypes.func,
    importCSV: PropTypes.bool,
    onImportCSV: PropTypes.func,
    columns: PropTypes.arrayOf(PropTypes.shape({
        field: PropTypes.string.isRequired,
        headerName: PropTypes.string.isRequired,
    })).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    edit: PropTypes.bool,
    onEdit: PropTypes.func,
};

export default GenericTable;
